@media (max-width: 2000px) {
  .projects-container {
    margin-bottom: 6%;
  }
  .yeartitle {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .project-box {
    height: 350px;
    margin-right: 50px;
    width: 550px;
  }
  .project-box-h {
    border-radius: 15px;
    height: 350px;
    margin-right: 50px;
    width: auto;
  }
  .image-container {
    margin-right: 50px;
  }
  .grey-overlay {
    height: 350px;
    margin-right: 50px;
    opacity: 1;
    width: 550px;
  }
  .grey-description-short {
    display: none;
  }
}

@media (max-width: 1025px) {
  .description {
    font-size: 1rem;
  }
  .description-container {
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .projects-container {
    margin-bottom: 10%;
  }
  .yeartitle {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .image-container {
    margin-right: 80px;
  }
  .project-box {
    height: 350px;
    margin-right: 50px;
    width: 550px;
  }
  .project-box-h {
    border-radius: 15px;
    height: 350px;
    margin-right: 50px;
    width: auto;
  }
  .grey-overlay {
    height: 350px;
    margin-right: 50px;
    opacity: 1;
    width: 550px;
  }
  .grey-description-short {
    display: none;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 10px;
  }
  .description {
    font-size: 1rem;
  }
  .description-container {
    margin-top: 22%;
    margin-bottom: 22%;
  }
  .projects-container {
    margin-bottom: 10%;
    height: auto;
  }
  .yeartitle {
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .image-container {
    margin-right: 0px;
  }
  .project-box {
    height: 200px;
    margin-right: 50px;
    width: 320px;
  }
  .project-box-h {
    border-radius: 15px;
    height: 200px;
    margin-right: 50px;
    width: auto;
  }
  .grey-overlay {
    height: 200px;
    margin-right: 3%;
    opacity: 1;
    width: 320px;
  }
  .grey-description {
    display: none;
  }
  .image-container-bottom {
    margin-bottom: 20%;
  }
}

.page-wrap {
  background-color: #f1faff;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
}

.toggle-container {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
}

.title-container {
  height: auto;
  width: 100vw;
  text-align: flex-start;
}

.description-container {
  height: auto;
  width: 100vw;
  text-align: flex-start;
}

.description {
  font-family: "Roboto";
  font-size: 2rem;
  font-weight: 400;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 8%;
  margin-right: 8%;
}

/* .description-links {
    text-decoration: none;
    color: #FEFFDD;
} */

.grass-humus {
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  height: 15px;
}

.topsoil {
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  height: 25px;
}

.yeartitle {
  font-family: "Roboto";
  font-size: 2rem;
  font-weight: 700;
  margin-left: 25px;
}

.projects-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 3%;
  overflow-x: auto;
  width: 100vw;
}
.projects-container::-webkit-scrollbar {
  display: none;
}

.project-box {
  flex: 0 0 auto;
  border-radius: 15px;
  transition: opacity 0.2s ease-in-out;
}

.project-box:hover {
  opacity: 0.9;
}

.image-container {
  position: relative;
  width: 550px;
  margin-left: 5%;
}

.painting-container {
  position: relative;
  width: auto;
}

.image-container-bottom {
  position: relative;
  width: 550px;
  margin-left: 5%;
}

.grey-overlay {
  background-color: #1f1f1f;
  border-radius: 15px;
  flex: 0 0 auto;
  transition: opacity 0.2s ease-in-out;
}

.grey-overlay:hover {
  opacity: 0.9;
}

.grey-title-container {
  text-align: center;
}

.grey-title {
  color: #feffdd;
  font-family: "Roboto";
  font-size: 2rem;
  font-weight: 700;
  padding-top: 8%;
}

.tech-boxes-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: auto;
}

.techbox {
  background-color: #373737;
  border-radius: 8px;
  margin: 5px 10px;
  text-align: center;
}

.techbox-text {
  color: #bcbcbc;
  font-family: "Roboto";
  font-size: 1.2rem;
  font-weight: 700;
  margin: 5px 15px;
  text-align: center;
}

.grey-des-container {
  text-align: flex-start;
  width: auto;
}

.grey-description {
  color: #a4a4a4;
  font-family: "Roboto";
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 15px 45px;
  text-align: left;
}

.grey-description-short {
  color: #a4a4a4;
  font-family: "Roboto";
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 15px 45px;
  text-align: left;
}

.test-container {
  background-color: bisque;
  display: flex;
  justify-content: flex-end;
  width: 100vw;
}
