.stars-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  will-change: opacity;
}

.star {
  position: absolute;
  background-color: #FEFFDD;
  border-radius: 50%;
  opacity: 0.7;
  animation: twinkle ease-in-out infinite;
  will-change: opacity;
}

@keyframes twinkle {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.8; }
} 