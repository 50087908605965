.switch {
  display: inline-block;
  margin-top: 5%;
  margin-right: 6%;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #b5b5b5;
  transition: 0.3s;
}

.slider:before {
  position: absolute;
  content: "";
  background-color: #f1faff;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #f5f7a2;
  box-shadow: 0 0 3px #feffdd, 0 0 6px #feffdd, 0 0 9px #feffdd;
}

input:checked + .slider {
  background-color: #181819;
}

.slider.rounded {
  border-radius: 50px;
}

.slider.rounded:before {
  border-radius: 50%;
}
