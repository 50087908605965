@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@media (max-width: 2000px) {
    html {
        font-size: 14px; 
    }
    .island-container {
        flex-direction: row;
    }
    .island {
        width: 200px;
        height: 200px;
    }
    .webring {
        width: 50px;
        height: 50px;
    }
    .switch {
        width: 120px;
        height: 45px;
    }
    .slider:before {
        height: 34px;
        width: 34px;
        left: 6px;
        bottom: 5.5px;
    }
    input:checked + .slider:before {
        transform: translateX(74px);
    }
}

@media (max-width: 1025px) {
    html {
        font-size: 14px; 
    }
    .island-container {
        margin-top: 5%;
        flex-direction: row;
    }
    .island {
        width: 150px;
        height: 150px;
    }
    .webring {
        width: 50px;
        height: 50px;
    }
    .switch {
        width: 120px;
        height: 45px;
    }
    .slider:before {
        height: 34px;
        width: 34px;
        left: 6px;
        bottom: 5.5px;
    }
    input:checked + .slider:before {
        transform: translateX(74px);
    }
}

@media (max-width: 600px) {
    html {
        font-size: 10px;
    }
    .island-container {
        flex-direction: column;
        margin-top: 25px;
    }
    .island-and-logo {
        margin-bottom: 40px;
    }
    .island {
        width: 150px;
        height: 150px;
    }
    .webring {
        width: 40px;
        height: 40px;
    }
    .switch {
        width: 80px;
        height: 35px;
    }
    .slider:before {
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
    }
    input:checked + .slider:before {
        transform: translateX(45px);
    }
}

/* */

/* .page-wrap {
    background-color: #F1FAFF;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
} */

.top-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.webring-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 2%;
    margin-left: 2%;
    width: 50vw;
}


.toggle-container {
    display: flex;
    justify-content: flex-end;
    width: 50vw;
}

.test {
    background-color: #ddd;
    width: 100px;
    height: 80px;
}

.main-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 100px;
    width: 100vw;
    height: auto;
}

/* .name {
    color: #5A5A5A;
    font-family: 'Roboto';
    font-size: 3.5rem;
    font-weight: 400;
    margin-top: 5%;
} */

.links-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 2%;
    width: 100vw;
}

.link-text {
    color: #5A5A5A;
    font-family: 'Source Code Pro', monospace;
    font-size: 1.5rem;
    font-weight: 400;
    color: 0.3s ease;
}

.link-text:hover {
    color: #272727;
}

.island-container {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 2%;
    width: 100vw;
}

.island-and-logo {
    display: flex;
    flex-direction: column;
    transition: filter 0.3s ease;
}
.island-and-logo:hover {
    filter: brightness(130%);
  }

.island-logo {
    color: #5A5A5A;
    font-family: 'Roboto';
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
}

.island1 {
    animation: floatEffect 5s ease-in-out infinite;
}
.island2 {
    animation: floatEffect 4s ease infinite;
}
.island3 {
    animation: floatEffect 3s linear infinite;
}

@keyframes floatEffect {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-7px);
    }
}


/* dark mode */

.dark-link-text {
    /* animation: glow 1s ease-in-out infinite alternate; */
    color: #FEFFDD;
    color: 0.3s ease;
    font-family: 'Source Code Pro', monospace;
    font-size: 1.5rem;
    font-weight: 400;
    text-shadow: 0 0 1px #FEFFDD, 0 0 2px #FEFFDD, 0 0 3px #FEFFDD;
}

.dark-link-text:hover {
    color: #fff;
}

.dark-island-logo {
    animation: glow 1s ease-in-out infinite alternate;
    color: #FEFFDD;
    font-family: 'Roboto';
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 0 1px #FEFFDD, 0 0 2px #FEFFDD, 0 0 3px #FEFFDD;
}

@keyframes glow {
    from {
        text-shadow: 0 0 0px #FEFFDD, 0 0 0px #FEFFDD, 0 0 0px #FEFFDD;    }
    to {
        text-shadow: 0 0 2px #FEFFDD, 0 0 4px #FEFFDD, 0 0 6px #FEFFDD;    }
  }
  